<template>
  <div id="page_profil" class="grid admintext-demo">
    <Toolbar class="toolbar_top">
      <template v-slot:start>
        <div class="my-2">
          <h3>Profile</h3>
        </div>
      </template>
    </Toolbar>
    <div class="surface-ground px-4 flex align-items-center justify-content-center">
      <div class="surface-card p-4 shadow-2 border-round w-full lg:w-12">
        <div class="text-center mb-5">
          <Avatar :label="avatarUser" class="mr-2" size="large" shape="circle"></Avatar>
          <!--        <img src="demo/images/avatar/profile.jpg" />-->
          <div class="text-900 text-3xl font-medium mb-3">{{ user.firstname }} {{ user.lastname }} </div>
        </div>
        <div class="formgrid grid">

          <!--        <div class="field col">-->
          <!--          <label for="langue">Mme/M <span>*</span> </label>-->
          <!--          <Dropdown v-model="valueGender" :options="valuesGenders" optionLabel="gender" required="true"-->
          <!--                    autofocus :class="{'p-invalid': submitted && !user.name}"/>-->
          <!--        </div>-->
          <div class="field col">
            <label for="titre">{{t('firstname')}}* :</label>
            <InputText id="name" v-model.trim="user.firstname" required="true" autofocus
                       :class="{'p-invalid': submitted && !user.firstname}"/>
            <small class="p-invalid" v-if="submitted && !user.firstname">{{t('firstNameRequired')}}</small>
          </div>
          <div class="field col">
            <label for="titre">{{t('lastname')}}* :</label>
            <InputText id="name" v-model.trim="user.lastname" required="true" autofocus
                       :class="{'p-invalid': submitted && !user.lastname}"/>
            <small class="p-invalid" v-if="submitted && !user.lastname">{{t('lastNameRequired')}}</small>
          </div>
        </div>
        <!--      <div class="formgrid grid">-->

        <!--   -->
        <!--&lt;!&ndash;        <div class="field col">&ndash;&gt;-->
        <!--&lt;!&ndash;          <label for="titre">Login* :</label>&ndash;&gt;-->
        <!--&lt;!&ndash;          <InputText id="name" v-model.trim="user.phone" required="true" autofocus&ndash;&gt;-->
        <!--&lt;!&ndash;                     :class="{'p-invalid': submitted && !user.phone}"/>&ndash;&gt;-->
        <!--&lt;!&ndash;          <small class="p-invalid" v-if="submitted && !user.phone">Phone is required.</small>&ndash;&gt;-->
        <!--&lt;!&ndash;        </div>&ndash;&gt;-->

        <!--      </div>-->
        <div class="formgrid grid">
          <div class="field col">
            <label for="email">{{t('email')}}* :</label>
            <InputText id="email" v-model.trim="user.email" required="true" autofocus
                       :class="{'p-invalid': submitted && !user.email}"/>
            <small class="p-invalid" v-if="submitted && !user.email">{{t('emailRequired')}}</small>
            <small class="p-invalid" v-if="submitted &&  !validateEmail(user.email)">{{t('invalidMail')}}</small>

          </div>


          <div class="field col">
            <label for="phone">{{t('fixedTel')}} :</label>
            <InputText id="phone" v-model.trim="user.phone" required="true" autofocus/>

          </div>

        </div>
        <div class="formgrid grid">
          <div class="field col">
            <label for="position">Position :</label>
            <InputText id="position" v-model.trim="user.position" required="true" autofocus/>

          </div>

<!--          <div class="field col">-->
<!--            <label for="department">{{t('department')}}* :</label>-->
<!--            <InputText id="department" v-model.trim="user.department" required="true" autofocus-->
<!--                       :class="{'p-invalid': submitted && !user.department}"/>-->
<!--            <small class="p-invalid" v-if="submitted && !user.department">{{t('departmentRequired')}}</small>-->
<!--          </div>-->
          <div class="field col">
            <label for="mobile">Mobile :</label>
            <InputText id="mobile" v-model.trim="user.mobile" required="true" autofocus/>

          </div>

        </div>
        <div class="formgrid grid">



<!--          <div class="field col">-->
<!--            <label for="siteService">Site ou service :</label>-->
<!--            <InputText id="siteService" v-model="user.website"  rows="5" cols="20"/>-->
<!--          </div>-->

          <div class="field col">
            <label for="siteService">Site {{t('or')}} service :</label>
            <InputText id="siteService" v-model.trim="user.website" autocomplete="off"/>

          </div>

          <div class="field col">
          </div>
        </div>

<!--        <Button label="Réinitialser le mot de passe"  class="w-full" @click="resetPassword()"></Button>-->
        <div class="formgrid grid">
          <div class="field col">
        <a href="javascript:void(0)"  @click="resetPassword()">

          <span>{{t('resetPassword')}}</span>
        </a>
          </div>
        </div>
        <div class="formgrid grid" v-if="resetPass">


          <!--        <div class="field col">-->
          <!--          <label for="titre">Mot de passe :</label>-->
          <!--          <InputText id="name" v-model.trim="user.plain_password" required="true" autofocus-->
          <!--                     :class="{'p-invalid': submitted && !user.plain_password}"/>-->
          <!--          <small class="p-invalid" v-if="submitted && !user.plain_password">Le password is required.</small>-->
          <!--        </div>-->
          <div class="field col">
            <label for="password">{{t('password')}} :</label>
            <Password id="password" v-model="user.plain_password" required="true" toggleMask :feedback="false"
                      :class="{'p-invalid': submitted && !confirm }" style="display: block"/>
<!--            <small class="p-invalid" v-if="submitted && !user.plain_password">Password is required.</small>-->
            <small class="p-invalid"
                   v-if="submitted && ( user?.plain_password?.length<8 || !checkPassword(user.plain_password))">
              {{t('validatePassword')}}</small>

<!--            <small class="p-invalid"-->
<!--                   v-if="submitted && !checkPassword(user.plain_password)">Le mot de passe doit contenir un caractére special,</small>-->
<!--        <small class="p-invalid"-->
<!--                           v-if="submitted && !checkPasswordNb(user.plain_password)">Le mot de passe doit contenir un chiffre,</small>-->

            <small class="p-invalid"
                   v-if="submitted && (!confirm && user?.plain_password?.length>=8  && checkPassword(user.plain_password))">{{t('passwordDidNotMatch')}}</small>
          </div>
          <div class="field col">
            <label for="confirmPasswoed">{{t('confirmPassword')}} :</label>
            <Password id="confirmPasswoed" v-model="confirm_password" required="true" toggleMask :feedback="false"
                      :class="{'p-invalid': submitted && !confirm}" style="display: block"/>
<!--            <small class="p-invalid" v-if="submitted && !user.confirm_password">Confirm password is required.</small>-->
<!--            <small class="p-invalid"-->
<!--                   v-if="submitted && confirm_password.length<8">Le mot de passe doit contenir au minimum 8 caractères,</small>-->

<!--            <small class="p-invalid"-->
<!--                   v-if="submitted && !checkPassword(confirm_password)">Le mot de passe doit contenir un caractére special,</small>-->
<!--            <small class="p-invalid"-->
<!--                   v-if="submitted && !checkPasswordNb(confirm_password)">Le mot de passe doit contenir un chiffre,</small>-->


            <small class="p-invalid"
                   v-if="submitted && !confirm">{{t('passwordDidNotMatch')}}</small>

          </div>

        </div>
        <Button label="Modifier" icon="pi pi-user" class="w-full" @click="saveUser()"></Button>
      </div>
    </div>
    <Toast/>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import {usePrimeVue} from "primevue/config";

export default {

  name: "Profile",

  data() {
    return {
      userDialog: false,
      user: {},
      valuesGenders: [
        {'id': 1, 'gender': 'Monsieur'},
        {'id': 2, 'gender': 'Madame'},
      ],
      valueGender: '',
      submitted: false,
      confirm_password: '',
      confirm : true,
      resetPass: false,
    }
  },
  setup() {
    const primevue = usePrimeVue();
    const store = useStore();
    return {store, primevue, t: primevue.config.StoreTranslation.t};
  },

  mounted() {
    this.store.dispatch(Actions.PROFILE, {'customHeader': false,}).then(data => {
      this.user = data;
      this.avatarUser =  data.firstname[0]  + ' ' + data.lastname[0]

    })
  },
  methods: {
    modalOpenProfile() {
      this.userDialog = true
    },
    saveUser() {

      this.submitted = true
      this.confirm = true

      if(this.user.plain_password)
      {
        if(this.user.plain_password != this.confirm_password)
        {
          this.confirm = false
        }
      }

      delete this.user.customer;
      let modifEmail= true
      if(this.user.email == this.emailModif)
      {
        modifEmail = false
        delete this.user['email']
      }

      if ((this.user.email || !modifEmail) && this.user.firstname && this.user.lastname && this.confirm && (this.checkPassword(this.user.plain_password ) )  && this.validateEmail()) {
        delete this.user['user_sites']
        this.store.dispatch(Actions.PUT_USER, this.user).then(data => {
          this.avatarUser = data.lastname[0] + ' ' + data.firstname[0]
          this.$toast.add({severity:'success', detail:this.t('userHasNotAnserToAllQuiz'), life: 3000});
        }).catch(error => {
          console.log(error)
        })
      }
    },
    hideDialog() {
      this.userDialog = false
    },
    resetPassword(){
      if(!this.resetPass){
      this.resetPass = true
      }
      else {
        this.resetPass = false
      }
    },
    checkPassword(str)
    {
      if(this.resetPass){
      // let re = /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/;
      let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\]{};':"\\|,.<>?~])(?=.{8,})/;
      return re.test(str);
      }
      else {
        return true
      }
    },
    checkPasswordNb(str)
    {
      let re = /[ ?=.*\d]/;
      return re.test(str);
    },
    validateEmail(mail) {

      if(mail){
        let re = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+$/;
        return re.test(mail);
      }
      return true
    }
  },
}
</script>

<style lang="scss" scoped>
#page_profil {
.formgrid{
  .field{
    max-width: 302px;
  }
}
}
@media (max-width: 480px) {
  #page_profil {
    .formgrid {
      flex-direction: column;
    }
  }
}
</style>
